/* Reset */
body {
    margin: 0;
}

* {
    box-sizing: border-box;
}

/* Global */

:root {
  --color-palette-white: #ffffff;
  --color-palette-lightGrey: #eeeeee;
  --color-palette-grey: #cccccc;
  --color-palette-darkGrey: #b1b1b1;
  --color-palette-green: #97cc04;
  --color-palette-blue: #42aad8;
  --color-palette-orange: #ee8434;
  --color-palette-brown: #967a53;
  --color-palette-red: #db504a;
  --color-palette-yellow: #efca08;
  --color-palette-logo: #ffb71b;
  --box-shadow-light: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  --box-shadow-heavy: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

/* Fonts */

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 1.8em;
}

p {
    font-size: 1em;
}
