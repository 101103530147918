.Modal-container {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.Modal-card {
  margin: 7rem auto;
  width: 90%;
  max-height: calc(100vh - 8rem);
  max-width: 600px;
  position: relative;
  overflow: auto;
}

.Modal-cross {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 12px;
  font-size: 120%;
}

.Modal-title {
  text-align: center;
  margin: 0 0 1rem 0;
  padding: 0 0.35rem;
}
