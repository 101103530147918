.Button {
  min-height: 45px;
  line-height: 41px;
  min-width: 75px;
  margin: 0;
  font-size: 1em;
  background-color: var(--color-palette-lightGrey);
  border: 1px solid var(--color-palette-darkGrey);
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: var(--box-shadow-light);
}

.Button:active {
  box-shadow: none;
  background-color: var(--color-palette-grey);
}

.Button-reset {
  height: auto;
  min-width: unset;
  margin: 0;
  padding: 0;
  background-color: unset;
  border: none;
  border-radius: 0;
  cursor: pointer;
  text-decoration: none;
  box-shadow: none;
}

.Household-button {
  line-height: 10px;
  padding: 15px 5px;
}
