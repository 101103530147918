.default-map-pin span {
  background-color: #277fca;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -7px;
  top: -7px;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
}

.default-map-pin span:after {
  content: "";
  position: relative;
  background: white;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  z-index: 99;
  display: block;
  top: 9px;
  left: 9px;
}

.house-map-pin span {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -7px;
  top: -7px;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  color: white;
  z-index: 99;
}

.house-map-pin span:after {
  content: "home";
  display: block;
  font-size: 20px;
  font-weight: 900;
  transform: rotate(-45deg);
}

.position-map-pin span {
  background-color: #03a0db;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -7px;
  top: -7px;
  border: white solid 4px;
  position: relative;
  border-radius: 2rem;
  z-index: 101;
}

.icon-blue span {
  background-color: #277fca;
}

.icon-red span {
  background-color: #ca2727;
}

.icon-orange span {
  background-color: #da9500;
}

.icon-green span {
  background-color: #27ca35;
}
