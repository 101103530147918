.App-header {
  height: 64px;
  display: flex;
  align-items: center;
  width: 100%;
}

.App-header .Logo {
  height: 70px;
  width: auto;
}

.App-header .Logo-text {
  color: var(--color-palette-logo);
  font-size: 2rem;
  font-weight: 300;
}
