.label {
  margin-left: 10px;
}
.checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: var(--color-palette-lightGrey);
  border: 2px solid var(--color-palette-darkGrey);
  color: inherit;
}
.checkboxContainer {
  display: flex;
  align-items: center;
}
.checkbox::after {
  content: "";
  display: none;
}

.checkbox.checked::after {
  display: block;
  position: absolute;
  left: 29px;
  width: 5px;
  height: 10px;
  border: 1px solid var(--color-palette-orange);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
