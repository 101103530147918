.Input {
  height: 35px;
  min-height: 35px;
  min-width: 140px;
  margin: 0;
  padding: 0 0.5rem;
  background-color: var(--color-palette-lightGrey);
  border: none;
  outline: 1px solid var(--color-palette-darkGrey);
}

.Input::placeholder {
  text-align: center;
}

.Input-has-error {
  outline: 1px solid var(--color-palette-red);
}
