.textArea {
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  background-color: var(--color-palette-lightGrey);
  border: none;
  outline: 1px solid var(--color-palette-darkGrey);
  resize: vertical;
  font-family: inherit;
}

.textArea::placeholder {
  text-align: center;
}

.textArea-has-error {
  outline: 1px solid var(--color-palette-red);
}
