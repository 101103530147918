@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.Placeholder {
  background-color: #eee;

  overflow: hidden;
  position: relative;
}

.Placeholder:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-name: shimmer;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
