.breadCrumbContainer {
    background: darkgrey;
    margin: 10px -17px;
    padding-right: 20px;
    display: flex;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
}

.breadCrumbItem {
    margin-left: -10px;
}

.breadCrumbItem:first-child {
    margin-left: 5px;
}

.breadCrumbLink {
    padding: 10px;
    display: inline-block;
    background: darkgrey;
}

.breadCrumbLink {
    color: white;
    text-decoration: none;
}

.breadCrumbLink:first-child {
    padding-left: 10px;
}

.breadCrumbLink:visited {
    color: white;
    text-decoration: none;
}

.breadCrumbSeparator {
    border-top: 5px solid white;
    border-right: 5px solid white;
    width: 35px;
    height: 35px;
    display: inline-block;
    rotate: 45deg;
    vertical-align: middle;
    margin-left: -30px;
    margin-right: 3px;
    margin-top: -4px;
}
