.Button-bar {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.Button-bar .Button {
  flex-grow: 1;
  width: 140px;
  box-shadow: none;
  border-radius: unset;
}

.Button-bar .Button:not(:first-child) {
  border-left: none;
}
