.direction-right {
  transform: scaleX(-1);
}

.icon-right {
  float: right;
  width: 22px;
  height: 22px;
}

.icon-left {
  float: left;
  width: 22px;
  height: 22px;
}
