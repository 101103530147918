.Household-page .Buttons {
  margin-top: 1rem;
}

.Household-page .Buttons .Button {
  box-shadow: none;
  border-radius: 0;
  width: 50%;
}

.Household-page .Buttons .Button:first-child {
  border-right: 0;
}

.Household-page .List {
  margin-top: 1rem;
}

.Household-VI {
  color: #5a5a5a;
  font-size: 0.9rem;
}
