.VI-button {
  position: relative;
  text-align: center !important;
}

.VI-button .Spinner {
  display: block;
  margin: auto;
}

.rightArrow {
  position: absolute;
  top: 7px;
  right: 10px;
}
