.Input-search-autocomplete-container {
  position: relative;
}

.Input-search-autocomplete,
.Input-search-autocomplete-container {
  display: block;
  width: 100%;
}

.awaiting-results-container {
  position: absolute;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
