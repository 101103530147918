.Login-page {
  text-align: center;
}

.Login-page .Login-email-input,
.Login-page .Login-password-input,
.Login-page .Login-button {
  height: 45px;
  font-size: 110%;
}

.Login-page .Login-email-input,
.Login-page .Login-password-input {
  width: 90%;
  max-width: 500px;
}

.Login-page .Login-button {
  width: 50%;
  max-width: 250px;
}

.login-error {
  background: #f8d7da;
  padding: 10px;
  height: 40px;
  width: 400px;
  margin: 0 auto 1em;
  border-radius: 5px;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
