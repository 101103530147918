.Location-list .Button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HouseholdSelect-page .Buttons {
  margin-top: 1rem;
  display: flex;
}

.HouseholdSelect-page .Buttons .Button {
  box-shadow: none;
  border-radius: 0;
  flex-grow: 1;
}

.HouseholdSelect-page .Buttons .Button:not(:first-child) {
  border-left: 0;
}
#mapSpinnerOverLay {
  display: flex;
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-width: 600px;
  height: calc(100vh - 300px);
  background-color: #0000006b;
  justify-content: space-around;
  align-items: center;
}
#mapContainer {
  position: relative;
}
