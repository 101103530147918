.Button-list {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  border-radius: 2px;
}

.Button-list .Button {
  width: 100%;
  box-shadow: none;
  line-height: 1;
  text-align: left;
}

.Button-list > :first-child {
  border-radius: 2px 2px 0 0;
  border-bottom: none;
}

.Button-list > *:not(:first-child):not(:last-child) {
  border-radius: unset;
  border-bottom: none;
}

.Button-list > :last-child {
  border-radius: 0 0 2px 2px;
  border-bottom: 1px solid var(--color-palette-darkGrey);
}
