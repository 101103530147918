.Opentext-question {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Opentext-char-count {
  text-align: right;
  margin: 0.3rem 0;
  font-size: 13px;
}

.Opentext-question-over-limt {
  color: var(--color-palette-red);
}

.Opentext-question textarea {
  flex-grow: 1;
  min-height: 64px;
}

.Buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}
