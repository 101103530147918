.viHistory {
  display: flex;
  flex-direction: column;
}

#newIntention {
  margin: 10px 0px 10px 102px; /*margin left according to min-width of the labels in the list above*/
  display: flex;
  justify-content: center;
}

.acceptBlock {
  display: flex;
  height: 100px;
  width: 200px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: calc((100% - 200px) / 2);
}

.errorTextLocation {
  height: unset;
}
