/* Common spinner styles */

.Spinner {
  display: inline-block;
  position: relative;
}

.Spinner div {
  animation: Spinner 1.2s linear infinite;
}

.Spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  border-radius: 20%;
  background: var(--color-palette-darkGrey);
}

.Spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.Spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.Spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.Spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.Spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.Spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.Spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.Spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.Spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.Spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.Spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.Spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes Spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Small spinner styles */
.Spinner-small {
  width: 17.778px;
  height: 17.778px;
}

.Spinner-small div {
  transform-origin: 8.888px 8.888px;
}

.Spinner-small div:after {
  top: 0.667px;
  left: 8.222px;
  width: 1.333px;
  height: 4px;
}

/* Medium spinner styles */
.Spinner-medium {
  width: 26.667px;
  height: 26.667px;
}

.Spinner-medium div {
  transform-origin: 13.333px 13.333px;
}

.Spinner-medium div:after {
  top: 1px;
  left: 12.333px;
  width: 2px;
  height: 6px;
}

/* Large spinner styles */
.Spinner-large {
  width: 40px;
  height: 40px;
}

.Spinner-large div {
  transform-origin: 20px 20px;
}

.Spinner-large div:after {
  top: 1.5px;
  left: 18.5px;
  width: 3px;
  height: 9px;
}
