.Search-street-overlay {
    position: absolute;
    top: 105px;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;
}

.Search-street-spinner-container {
    width: 200px;
    display: block;
    margin: 55px auto;
    text-align: center;
}