.List {
  display: flex;
  width: 100%;
    margin-bottom: 1rem;
}

.List .Label {
  height: 32px;
  min-width: 102px;
  line-height: 30px;
  background-color: var(--color-palette-grey);
  border: 1px solid var(--color-palette-darkGrey);
  border-right: none;
  padding: 0 0.5rem;
}

.Items {
  background-color: var(--color-palette-lightGrey);
  padding: 0;
  flex-grow: 1;
  margin: 0;
}

.Item {
  height: 32px;
  line-height: 30px;
  border: 1px solid var(--color-palette-darkGrey);
  padding-left: 0.5rem;
  overflow: hidden;
}

.Items > *:not(:first-child) {
  border-top: none;
}
