.App-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  background-color: var(--color-palette-grey);
  border: 1px solid var(--color-palette-darkGrey);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2000;
  border-left: none;
  border-right: none;
}

.App-footer .Logout-button {
  width: 28px;
  height: 28px;
  margin: 0 auto;
  font-size: 16px;
}

.logout-button-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}